<header>
    <div class="container">
        <nav>
            <a routerLink="">To Do</a>
            <span>|</span>
            <a routerLink="/shopping-list">Shopping List</a>
            <span>|</span>
            <a routerLink="/notes">Notes</a>
        </nav>
        <a *ngIf="isAuthenticated" (click)="onLogout()">Logout</a>
    </div>
</header>