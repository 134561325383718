export const AUTH_SIGNUP_START = 'AUTH_SIGNUP_START';
export const AUTH_LOGIN_START = 'AUTH_LOGIN_START';
export const AUTH_AUTO_LOGIN = 'AUTH_AUTO_LOGIN';

export const AUTH_AUTHENTICATE_SUCCESS = 'AUTH_AUTHENTICATE_SUCCESS';
export const AUTH_AUTHENTICATE_FAIL = 'AUTH_AUTHENTICATE_FAIL';

export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_CLEAR_ERROR = 'AUTH_CLEAR_ERROR';

export const TODO_GET_TODOS = 'TODO_GET_TODOS';
export const TODO_SET_TODOS = 'TODO_SET_TODOS';

export const TODO_SEND_TODO = 'TODO_SEND_TODO';
export const TODO_ADD_TODO = 'TODO_ADD_TODO';

export const TODO_UPDATE_TODO = 'TODO_UPDATE_TODO';
export const TODO_REMOVE_TODO = 'TODO_REMOVE_TODO';

export const TODO_CHANGE_ORDER = 'TODO_CHANGE_ORDER';

export const TODO_START_EDIT = 'TODO_START_EDIT';
export const TODO_STOP_EDIT = 'TODO_STOP_EDIT';