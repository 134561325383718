<div *ngIf="isLoading">
    <app-loading-spinner></app-loading-spinner>
</div>

<form [formGroup]="userForm" #formDirective="ngForm" (ngSubmit)="onSubmit()" *ngIf="!isLoading">
    <div class="form" [hidden]="isLoginMode">
        <mat-form-field [hideRequiredMarker]="true">
            <mat-label>Enter your Name</mat-label>
            <input type="text" matInput formControlName="name" class="form-input-field"
                required="{{isLoginMode?false:true}}">
            <mat-error *ngIf="userForm.get('name')?.hasError('required')">Name is required.</mat-error>
        </mat-form-field>
    </div>

    <div class="form">
        <mat-form-field [hideRequiredMarker]="true">
            <mat-label>Enter your Email</mat-label>
            <input type="email" matInput formControlName="email" class="form-input-field" />
            <mat-error *ngIf="userForm.get('email')?.hasError('required')">Email is required.</mat-error>
            <mat-error *ngIf="userForm.get('email')?.hasError('email')">Please enter a valid email address.</mat-error>
        </mat-form-field>
    </div>

    <div class="form">
        <mat-form-field [hideRequiredMarker]="true">
            <mat-label>Enter your Password</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="userForm.get('password')?.hasError('required')">Password is required.</mat-error>
            <mat-error *ngIf="userForm.get('password')?.hasError('minlength')">Password must be at least 8
                characters long.</mat-error>
        </mat-form-field>
    </div>

    <div class="submitButtons">
        <button mat-button type="submit" [disabled]="!userForm.valid">{{isLoginMode? 'Login' : 'SignUp'}}</button> |
        <button mat-button type="button" (click)="onSwitchMode()">Switch to {{isLoginMode ? 'SignUp' :
            'Login'}}</button>
    </div>
</form>